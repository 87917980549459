import Home from "./pages/home/Home";
import TopBar from "./components/topbar/TopBar";
import Blog from "./pages/blog/Blog";
import Single from "./pages/single/Single";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
//import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

/*
<Router>
<Routes>
  <Route exact path="/">
    <Home />
  </Route> 
  <Route path="/post/:postId">
    <Single />
  </Route>
</Routes>
</Router>
*/

function App() {
  return (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />}/>
        <Route index element={<Home />} />
        <Route path="/explore" element={<Blog />}/>
        <Route path="/post/:postId" element={<Single/>}></Route>      
    </Routes>
  </BrowserRouter>
  );
}

export default App;
