//import logo from './logo.svg';
import Blog from "../blog/Blog";
import hero from "./../../images/bitomic-bg.png";
import icon1 from "./../../images/icon1.svg";
import icon2 from "./../../images/icon2.svg";
import icon3 from "./../../images/icon3.svg";
import giflogolayer from "./../../images/giflogolayer.gif";
import logo from "./../../images/logo.png";
import forbes from "./../../images/forbes.png";
import newsbtc from "./../../images/newsbtc.png";
import bloom from "./../../images/bloom.png";
import bitcoin from "./../../images/bitcoin.png";
import marketwatch from "./../../images/marketwatch.png";
import crypto from "./../../images/crypto.png";
import businessinsider from "./../../images/businessinsider.png";
import coinegrph from "./../../images/coinegrph.png";
import finimize from "./../../images/finimize.png";
import instagram from "./../../images/icons/Insta-256.png";
import linkedin from "./../../images/icons/Linkedin-256.png";
import twitter from "./../../images/icons/Twitter-256.png";
import email from "./../../images/icons/Email-256.png";
import "./home.css";
/*
        <a href="#"><img src= {instagram} alt="icon" class="topimg"/></a>
        <a href="#"><img src= {twitter} alt="icon" class="topimg" /></a>

        <div class="serviceslistmobile">
<p class="fsize hideservices">Influencer Marketing</p>
    <p class="fsize hideservices">Community Management</p>
    <p class="fsize hideservices">SocialMedia Marketing</p>
    <p class="fsize hideservices">Content Creation</p>
    <p class="fsize hideservices">PR Services</p>
</div>
*/
function App() {
  return (
    <div className="App">

<div id="fullpage" >
  <div class="section widepad firstbg">
    <div class="heropart herodiv">
      <div class="heroleft" >
        <p class="top-text">Crypto,&nbsp;Web3,&nbsp;NFT <br/>Branding,&nbsp;and <br/> Marketing</p>
        <p class="mar-top">Imergey Bitomic is born in Web3.0. We offer branding and  marketing services and create "storybrands" for game changing crypto, NFT, and blockchain projects.  Discover how we can make your project world renowned.</p>
        <a href="/explore" class="btn mt30 animation">Insights <i class="fas fa-arrow-right"></i></a>
    
        <div class=" mar-top">
        <a href="mailto:clientservices@imergey.com?subject=Message to Bitomic-Imergey&cc=cc@example.com"><img src= {email} alt="icon" class="topimg"/></a>
        <a href="https://www.linkedin.com/company/bitomic/"><img src= {linkedin} alt="icon" class="topimg"/></a>
        </div>
        
      </div>
      <div class="heroright mr-auto firstimg text-center">
        <img src={hero} alt="Bitomic" class ="img-fluid"/>        
      </div>
    </div>        
  </div>

  <div class="section widepad meshbg">
    <div>
      <p class="section2mar"></p>
    </div>
    <div class="part2logo logoprt ">
        <img src= {giflogolayer} alt="logo" class="img-fluid gif "/>
        <img src= {logo} alt="logo" class="img-fluid "/>
    </div>
    <div class="heropart herodiv">
      <div class="heroleft">
        <div class="mediablock ">
          <div class="iconside"><img src={icon1} alt="icon"/></div>
          <div class="contentside">
            <h6 >We Weave Your Story Brand</h6>
            </div>
        </div>

        <div class="mediablock mar-top">
          <div class="iconside"><img src={icon2} alt="icon"/></div>
          <div class="contentside">
            <h6>We Create Content and <br/> Manage Online Channels</h6>
            </div>
        </div>

        <div class="mediablock mar-top">
          <div class="iconside"><img src={icon3} alt="icon"/></div>
          <div class="contentside">
            <h6>We Promote Your Brand and <br/> Create Communities</h6>
            </div>
        </div>
      </div>

      <div class="heroright heroright2 mr-auto">
      <h2>Building A Global Brand? Leave it on us</h2>
      <p class="mar-top fsizetwo"> Imergey Bitomic is your one wholistic digital marketing and branding partner. We help you manage your project end-to-end right from forming a brand strategy to executing campaigns with content creation, community management and promoting your brand with the biggest names in Web3.0.</p>
      </div>
    </div>        
  </div>

<div class="section widepad meshbg">
<h2 class="servicesfont servicesmar ">Our Services</h2>
<p class="servmar1"></p>
<div >
<div class="container ">
    <div class="column-left">
    <h6 >Influencer Marketing</h6>
    <p class="hideclass fsize1">Influencers have proven to be one of the most effective mediums of reaching a highly-engaged Web3.0 audience. Bitomic can help you leverage crypto influencers to get your message in front of a global audience.</p>
    </div>
    <div class="column-center">
    <h6 >Community Management</h6>
    <p class="hideclass fsize1">A project's community is core to its success. Building a community for your Web3.0 brand is absolutely non-negotiable.</p>
    </div>
    <div class="column-right">
    <h6 >Social Media Marketing</h6>
    <p class="hideclass fsize1">Make your social media channels stand out with our comprehensive Web3.0 techniques and disruptive marketing strategies.</p>
    </div>
</div>
<div class="hideclass ">
<p class="servmar"></p>
</div>
<div class="servicescontainer ">
 <div>
<h6 >Content Creation</h6>
<p class="fsize1 hideclass">Content writing addresses most messaging challenges that crypto and blockchain brands face. At Bitomic we create engaging, expert-written content for your needs designed to convert your target users.</p>
  </div>
  <div>
<h6 >PR Services</h6>
<p class="fsize1 hideclass">Transform your marketing strategy with specialized crypto publications. Bitomic media partners can generate unparralleled widespread awareness around your product.</p>
  </div>
</div>

<div class="serviceslist mt80 ">
    <p class="fsize">Exchange Listing</p>
    <p class="fsize">Landing Page</p>
    <p class="fsize">Airdrop Promotions</p>
    <p class="fsize">Whitepaper</p>
    <p class="fsize">Advertising</p>
    <p class="fsize">Discord</p>
    <p class="fsize">Instagram</p>
    <p class="fsize">Steemit</p>
    <p class="fsize">Reddit</p>
    <p class="fsize">Cryptotalk</p>
  </div>
</div>
</div>

  <div class="section widepad meshbg">
<div >
<p class="mt40"></p>
<br/>
</div>
  <div class="part2logo logoprt ">
    <img src= {giflogolayer} alt="logo" class="img-fluid gif"/>
    <img src= {logo} alt="logo" class="img-fluid"/>
 </div>
 <div >
  <p class="mt40"></p>
  <br/>
</div>
    <h2 class="servicesfont ">Access To Media </h2>

    <div class="serviceslist mar-top">
        <a href="#"><img src= {forbes} alt="icon"/></a>
        <a href="#"><img src= {newsbtc} alt="icon"/></a>
        <a href="#"><img src= {bloom} alt="icon"/></a>
        <a href="#"><img src= {bitcoin} alt="icon"/></a>
        <a href="#"><img src= {finimize} alt="icon" class="mt10"/></a>
        <a href="#"><img src= {marketwatch} alt="icon"/></a>
        <a href="#"><img src= {crypto} alt="icon"/></a>
        <a href="#"><img src= {businessinsider} alt="icon"/></a>
        <a href="#"><img src= {coinegrph} alt="icon"/></a>
    </div>

</div>


<div class="section widepad meshbg" id ="aboutus">

<h2 class="servicesfont mt80 ">About us </h2>
<p class="fsize2 mar-top">IMERGEY began as an online branding agency for executives and companies to craft their stories,  build an audience, and to achieve their marketing goals and objectives. <strong>Bitomic</strong> is the Web3.0 branch of IMERGEY, with clients in NFTs, crypto, and DeFi. Bitomic collaborates with thought leaders and companies to grow communities, maximize engagement, engage a global audience, and to lay the groundwork to achieve optimal brand awareness.</p>
<div class="bt-mar"></div>
<div class="flexbox ">
  <div class="leftside ">
  <a href="/explore" class="btn mt30 animation ">Insights<i class="fas fa-arrow-right"></i></a>
  </div>
  <div class="rightside connectwithus ">
    <h6>Connect with us</h6>
    <div class="mar-top">
        <a href="mailto:clientservices@imergey.com?subject=Message to Bitomic-Imergey&cc=cc@example.com"><img src= {email} alt="icon" class="aboutusimg"/></a>
        <a href="https://www.linkedin.com/company/bitomic/"><img src= {linkedin} alt="icon" class="aboutusimg"/></a>
      </div>
  </div>
</div>
</div>   
  </div>
  </div>

  );
}

export default App;
