import { useEffect, useState } from "react";

import TopBar from "../../components/topbar/TopBar";
import MidBar from "../../components/Midbar/Midbar";
import Posts from "../../components/Posts/Posts";
import Footer from "../../components/Footer/Footer";
//import Sidebar from "../../components/sidebar/Sidebar";
import axios from "axios";
import { useLocation } from "react-router";
import "./blog.css";

export default function Home() {
    const [posts, setPosts] = useState([]);
    const { search } = useLocation();
    const baseUrl = `https://apis-a39rzgfdx-imergey-jyothi.vercel.app/api/posts`;

    useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(baseUrl);
      setPosts(res.data);
    };
    fetchPosts();
  }, [search]);

  return (
    <div class="containerblog">
      <TopBar />
      <MidBar/>
      <div>
            <h3 class="tagh1"> Explore the world of Web 3.0 Marketing</h3>
        </div>

      <div className="homeblog">
        <Posts posts={posts} />
      </div>
      <Footer/>
    </div>
  );
}
