import { useContext } from "react";
import { Link } from "react-router-dom";
import "./topbar.css";
import giflogolayer from "./../../images/giflogolayer.gif";
import logo from "./../../images/logo.png";
import Home from "../../pages/home/Home";

export default function TopBar() {
  //const { user, dispatch } = useContext(Context);
  //const PF = "https://apis-a39rzgfdx-imergey-jyothi.vercel.app/images/"
  //<img src={giflogolayer} alt= "logo" class="image2 im2"/>
  return (
    <div className="top">
      <div className="parent">
        <a href="/"><img src={logo} alt= "logo" class="image1 im1"/></a>
        
      </div>
    </div>
  );
}
