import "./footer.css";
import logo from "./../../images/logo.png";
import instagram from "./../../images/icons/Insta-256.png";
import linkedin from "./../../images/icons/Linkedin-256.png";
import twitter from "./../../images/icons/Twitter-256.png";
import email from "./../../images/icons/Email-256.png";

export default function Footer() {
 
  return (
    <>
<footer class="footer-section">
            <div class="footer-content pt-5 pb-5">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 mb-50">
                        <div class="footer-widget">
                            <div class="footer-logo">
                                <a href="/"><img src={logo} class="img-fluid" alt="logo"/></a>
                            </div>
                            <div class="footer-text">
                               
                            </div>
                            <div class="footer-social-icon">
                               
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>Services</h3>
                            </div>
                            <ul>
                                <li>Influencer Marketing</li>
                                <li>Community Management</li>
                                <li>Social Media Marketing</li>
                                <li>Content Creation</li>
                                <li>PR Services</li>
                                <li>Exchange Listing</li>
                                <li>Landing Page</li>
                                <li>Airdrop Promotions</li>
                                <li>Whitepaper</li>
                                <li>Advertising</li>
                                <li>Discord</li>
                                <li>Instagram</li>
                                <li>Steemit</li>
                                <li>Reddit</li>
                                <li>Cryptotalk</li>
                                
                            </ul>
                        </div>
                    </div>
                    <br/>
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>Follow us</h3>
                            </div>

                            <div >

        <a href="mailto:clientservices@imergey.com?subject=Message to Bitomic-Imergey&cc=cc@example.com"><img src= {email} alt="icon" class="topimg"/></a>
        <a href="https://www.linkedin.com/company/bitomic/"><img src= {linkedin} alt="icon" class="topimg"/></a>
        </div>

                        </div>
                    </div>
                </div>
         </div>
        <div class="copyright-area">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 text-center text-lg-left">
                        <div class="copyright-text">
                            <p>Copyright &copy; Bitomic-Imergey</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </footer>
    </>
  );
}
