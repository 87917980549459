import "./post.css";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
/*
<div className="postCats">
          {post.categories.map((c) => (
            <span className="postCat">{c.name}</span>
          ))}
        </div>
        <br/>
        
*/

const baseUrl = `https://apis-a39rzgfdx-imergey-jyothi.vercel.app/api/posts`;

export default function Post({ post }) {
  const PF = `https://apis-a39rzgfdx-imergey-jyothi.vercel.app/images/`;
  return (
    <div className="post">
      {post.photo && <img className="postImg" src={PF + post.photo} alt="" />}
      <div className="postInfo">
        <br/>
        <Link to={`/post/${post._id}`} className="link">
          <span className="postTitle ">{post.title}</span>
        </Link>
        <hr />
        <span className="postDate">
          {new Date(post.createdAt).toDateString()}
        </span>
        <span>
          <p className="postDesc">{post.desc}</p>
        </span>  
        <Link to={`/post/${post._id}`} className="link">
          <span className="btnpost redpost">Read more</span>
        </Link>
      </div>
    </div>
  );
}
