import "./midbar.css";

export default function Midbar() {
  //const { user, dispatch } = useContext(Context);
  //const PF = "https://apis-a39rzgfdx-imergey-jyothi.vercel.app/images/"

  return (
    <div className="mid">
        <p class="htag">Blog</p>

    </div>
  );
}
