import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Context } from "../../Context/context/Context";
import "./singlepost.css";

export default function SinglePost() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const baseUrl = `https://apis-a39rzgfdx-imergey-jyothi.vercel.app/api/posts/`;
  const fetchUrl = baseUrl+path;
  const [post, setPost] = useState({});
  const PF = "https://apis-a39rzgfdx-imergey-jyothi.vercel.app/images/";


  useEffect(() => {
    const getPost = async () => {
      try{
      const res = await axios.get(fetchUrl);
      setPost(res.data);
      }
      catch (e) {
        //console.log(e)
    }
  };
    getPost();
  }, [path]);



  return (
    <>


    <div className="singlePost">
       <div className="singlePostWrapper">
        {post.photo && (
          <img src={PF + post.photo} alt="" className="singlePostImg" />
        )}
        <div className="singlePostInfo">
        <h2 className="singlePostTitle">{post.title}</h2>
          <p className="singlePostDate">
            {new Date(post.createdAt).toDateString()}
          </p>
        </div>
        
          <p className="singlePostDesc">{post.desc}</p>
        
      </div>
    </div>
    </>
  );
}
